import {Injectable, OnDestroy}       from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, ActivatedRoute}  from '@angular/router';
import { Observable } from 'rxjs';
import {isNullOrUndefined} from "util";
import {Configuration} from "../../src/app/app.config";
import { ValidacionService } from './validation.service';
import { StorageService } from './storage.service';

// import { AuthService }      from './auth.service';
// import {Configuration} from "../../app.constants";

/**
 * The ActivatedRouteSnapshot contains the future route that will be activated and the RouterStateSnapshot
 * contains the future RouterState of our application, should we pass through our guard check.
 */
@Injectable()
export class ValidationGuard implements CanActivateChild, OnDestroy {

    validationSubscribe : any;
    activeRouteSubscribe : any;
    state : any;
    snapshot : any;

    constructor(private _storageService : StorageService, private _validacionService:ValidacionService, private _configuration: Configuration, private _router:Router, private activeRoute: ActivatedRoute) {
       // console.log("ValidationGuard Enable");
        this.state = _router.routerState;
        this.snapshot = this.state.snapshot;  
    }

    ngOnDestroy():void{
        if (this.validationSubscribe){
            this.validationSubscribe.unsubscribe();
        }
        if(this.activeRouteSubscribe){
            this.activeRouteSubscribe.unsubscribe();
        }
    }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :Observable<boolean> | Promise<boolean> | boolean {
    
    
    let token = this._storageService.getStorage('token');
    //console.log("canActive",token)
    return new Promise((resolve, reject)=>{
      this.validationSubscribe = this._validacionService.validateTokenMultimedia(token).subscribe((response: any) => {
        console.log("validateTokenMultimedia response > "+JSON.stringify(response));
        resolve(response.allow);
  
      },(error: any) => {
        //console.log(error);
        reject(false)
      });
    })
  }

}
