import { Component, OnInit, Inject } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { DOCUMENT } from '@angular/common';
import { ValidationService } from 'src/services/validate.service';
import { AuthService } from 'src/services/auth.service';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading : boolean = false;
  matcher = new MyErrorStateMatcher();
  loginForm : FormGroup;
  errMSG : string = '';
  
  constructor(private fb : FormBuilder, private authService: AuthService, @Inject(DOCUMENT) private document: any) {
    this.loginForm = this.fb.group({
      mail: ['', Validators.compose([Validators.required, ValidationService.emailValidator])],
      password : ['',Validators.required]
    });
  }

  ngOnInit() {
  }


   onSubmit(formValues : any){
    if(this.loginForm.controls['mail'].status=="INVALID"){
      this.loginForm.controls['mail'].setErrors({'email': true});
    }
     
    if(this.loginForm.valid){
      if(!this.isLoading){
        this.errMSG = '';
        this.isLoading = true;
        
        this.authService.login(formValues.mail, formValues.password).subscribe((res:any)=>{
          if(!res.failed){
            console.log("res", res)
          }else{
            this.errMSG = res.message;
          }
          this.isLoading = false;
        },(err:any)=>{
          
          this.isLoading = false;
        });
      }
    }
  }

}
