import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SplashComponent } from './components/splash/splash.component';
import { ValidationGuard } from 'src/services/guard.service';


const routes: Routes = [
  {
    path: 'acceso',
    component: LoginComponent 
  },
  {
    path: 'multimedia',
    loadChildren: './components/multimedia/multimedia.module#MultimediaModule',
    canActivateChild: [ValidationGuard]
  },
  {
    path: '',
    component: SplashComponent 
  },
  {
    path: ':watch',
    component: SplashComponent 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
