import {Injectable} from '@angular/core';
import {isNullOrUndefined} from "util";



@Injectable()
export class StorageService {

  private storage: any;

  constructor() {
    this.storage = localStorage;
  }

  
  public getStorage(key: string): any {
    var item = this.storage.getItem(key);
    if (!isNullOrUndefined(item) && item !== 'undefined') {
      return JSON.parse(this.storage.getItem(key));
    }
    return null;
  } 

  public setStorage(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));

  }
}
