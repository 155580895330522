import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidacionService } from 'src/services/validation.service';
import { StorageService } from 'src/services/storage.service';

@Component({
  selector: 'splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit, OnDestroy {
  
  activeRouteSubscribe : any;
  validationSubscribe : any;
  tokenMultimedia : string;
  errorMessage : string = '';
  constructor(private _storageService : StorageService, private _activatedRoute: ActivatedRoute, private _router : Router,  private _validacionService:ValidacionService) { }

  ngOnInit() {
    this.activeRouteSubscribe = this._activatedRoute.params.subscribe(params => {
      if(params['watch']){
        this.tokenMultimedia = params['watch'];
        this.validate(this.tokenMultimedia);
      }else{
        //console.log("no viene token");
        this.errorMessage = 'El acceso a esta multimedia a expirado'
      }
    });
  }

  ngOnDestroy(): void {
    if (this.validationSubscribe){
        this.validationSubscribe.unsubscribe();
    }
    if(this.activeRouteSubscribe){
        this.activeRouteSubscribe.unsubscribe();
    }
  }

  validate(token: string){
    this.validationSubscribe = this._validacionService.validateTokenMultimedia(token).subscribe((response: any) => {
      console.log("validateTokenMultimedia response splash > "+JSON.stringify(response));
      if(response.allow){
        this._storageService.setStorage('token', this.tokenMultimedia)
        this._storageService.setStorage('system', response.system)
        setTimeout(()=>{
          this._router.navigate(['multimedia'])
        },300)
      }else{
        //console.log("no pasa!")
        this.errorMessage = 'El acceso a esta multimedia a expirado'
      }
      
    },(error: any) => {
      console.log(error);
     
    });
  }
}
