import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Configuration } from 'src/app/app.config';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private config : Configuration) { 
   
  }

  login(email:string, password:string): Observable<any> {
    
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type':  'application/json'})
    };
     
    return this.http.post<any>(this.config.URL_BASE+"/auth", {email:email, password: password}, httpOptions).pipe(
      catchError(this.handleError<any>('loginService'))
    );
  }


  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
   
}
