import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {
    private environment: number = 1;
    public URL_BASE: string;
    public VERSION : string;

    constructor() {
        this.VERSION = "1.0";
        switch (this.environment) {
            case 0:  // dev
                this.URL_BASE = "http://localhost:4100";
                break;        
            case 1:  // prod
                this.URL_BASE = "https://player.megatime.cl/api";
                break;
        }
    }
    
}
