import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// ANGULAR MATERIAL
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';

// COMPONENTS
import { LoginComponent } from './components/login/login.component';
import { SplashComponent } from './components/splash/splash.component';

// SERVICES
import { AuthService } from 'src/services/auth.service';
import { ValidacionService } from 'src/services/validation.service';
import { Configuration } from './app.config';
import { ValidationGuard } from 'src/services/guard.service';
import { StorageService } from 'src/services/storage.service';
import { Ng2DeviceService } from 'ng2-device-detector';
import { QrComponent } from './components/multimedia/qr/qr.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SplashComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatButtonModule,
    MatInputModule
  ],
  providers: [Configuration, AuthService, ValidacionService, ValidationGuard, StorageService, Ng2DeviceService],
  bootstrap: [AppComponent]
})

export class AppModule { }
